<template>
    <layout-admin-dashboard-view
             :showToolbar="false"
    :backRoute="{name: 'admin-dashboard'}">
        <template v-slot:title>
            <span>{{translateTitleCase('admin.config.title')}}</span>
        </template>

        <template v-slot:default>
          <crud-list
                    :showStateColumn="false"
                    :showIdColumn="false"
                    :showSelectColumn="false"
                    :showOrderingColumn=" false"
                    :createRoute="false"
                    :apiGetUrl="'config/list'"
                    :allowDelete="false"
                    :allowCreate="false"
                    :apiUpdateUrl="getUpdateApiUrl"
                    :editRoute="getEditRoute"


            ></crud-list>
        </template>
    </layout-admin-dashboard-view>

</template>

<script>
    import { useStore } from 'vuex'

    import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
//    import paginationComposition  from '@/client/extensions/composition/pagination.js';
    import CrudList from '@/client/components/crud/List.vue';
    import { computed, reactive, getCurrentInstance } from 'vue';


    import { useMeta } from 'vue-meta'
    import {useI18n} from "vue-i18n/index";
    export default {
        setup (props, setupContext ) {
            let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
            let {t} = useI18n();
            let {meta} = useMeta( {
                'title': t('admin.config.title'),
            });
            return {asyncOps, asyncOpsReady, asyncStatus,meta}
        },
        props: {
            entityType: {
                type: String,
                default: 'default'
            }
        },
        components: {CrudList},

        data: function () {
            return {

            };
        },
        computed : {

        },
        methods: {
            getEditRoute(id, item) {
                return {name: 'admin-config-edit', params: { entryKey: item.entryKey}}
            },
            getUpdateApiUrl(arg) {
                return 'config/'+arg;
            },
        },
    }
</script>

<style scoped lang="scss">
    .meta-column-header{
        white-space: nowrap;
    }

    .list-column-header {
        position: relative;
        cursor: pointer;

        .col-sort-indicator {
            position: absolute;
            top: calc(50% + 1px);
            left: -5px;
            transform: translateY(-50%);
            z-index: 5;
            display: inline-block;
        }
    }
</style>
